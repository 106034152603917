// src/components/NestedMenu/NestedMenu.jsx

import React, { useEffect, useState, useCallback } from "react";
import "./NestedMenu.scss";
import { withTranslation, Link, i18n } from "../../../i18n";
import { CLEAN_API_URL } from "../../../context/appState";

const NestedMenu = ({ categories, close, isOpen, closeMenu, t, isInBurger = "test", closeBurger, makeListeners, language }) => {
    const [clicked, setClicked] = useState(null);
    const [isOpenSubMenu, setIsOpenSubMenu] = useState(false);

    useEffect(() => {
        makeListeners();
    }, [clicked, makeListeners]);

    const toggleSubMenu = () => {
        setIsOpenSubMenu(prevState => !prevState);
    };

    const handleClicked = cat => {
        setClicked(cat);
        setIsOpenSubMenu(true);
    };

    const handleCloseMenu = useCallback(() => {
        setClicked(null);
        close();
    }, [close]);

    //const { language } = i18n;


    useEffect(() => {
        if (isInBurger) {
            setClicked(null)
        }
    }, [isInBurger]);



    return (
        <section className={isOpen ? "nested_menu nested_menu--open" : "nested_menu"}>
            <ul>
                {categories
                    .filter(obj => obj && !["sale", "podarochnyy-sertifikat"].includes(obj.slug))
                    .map((cat, idx) => (
                        <li key={`nested-menu-${idx}`} className={`nested_menu__item${!cat.parent_id ? " top" : ""}`}>
                            {cat?.children && cat.children.length > 0 ? (
                                <button
                                    className="nested_menu__item--btn"
                                    onClick={() => handleClicked(cat)}
                                    key={cat.id}
                                >
                                    <img
                                        src={cat?.icon?.includes(CLEAN_API_URL) ? cat.icon : CLEAN_API_URL + cat.icon}
                                        alt=""
                                    />
                                    {language === "ua" ? cat.name_ua : cat.name}
                                </button>
                            ) : (
                                <Link href={`/categories/${cat.slug}`}>
                                    <a
                                        className="nested_menu__item--btn"
                                        href={`/categories/${cat.slug}`}
                                        onClick={handleCloseMenu}
                                        key={cat.id}
                                    >
                                        <img
                                            src={
                                                cat?.icon?.includes(CLEAN_API_URL) ? cat.icon : CLEAN_API_URL + cat.icon
                                            }
                                            alt=""
                                        />
                                        {language === "ua" ? cat.name_ua : cat.name}
                                    </a>
                                </Link>
                            )}

                            {cat?.children && cat.children.length > 0 && (
                                <button className="nested_menu__item--next" onClick={() => handleClicked(cat)}>
                                    Next
                                </button>
                            )}
                        </li>
                    ))}
            </ul>
            {clicked && clicked.children.length > 0 && (
                <RecursiveDropdown
                    isOpen={isOpen}
                    data={clicked}
                    prefix={clicked.slug}
                    openSubMenu={isOpenSubMenu}
                    close={() => setClicked(null)}
                    closeMenu={close}
                    closeSubMenu={toggleSubMenu}
                    makeListeners={makeListeners}
                />
            )}
        </section>
    );
};

const RecursiveDropdown = ({ data, prefix, close, closeMenu, openSubMenu, makeListeners, isOpen, closeSubMenu }) => {
    const [clicked, setClicked] = useState(null);
    const { language } = i18n;

    useEffect(() => {
        if (makeListeners) makeListeners();
    }, [clicked, makeListeners]);

    const handleClose = () => {
        if (closeSubMenu) closeSubMenu();
        closeMenu();
    };

    return (
        <>
            <section className={`nested_menu nested_menu--sub ${!openSubMenu ? "nested_menu--sub--close" : ""}`}>
                <div className="nested_menu__head">
                    <button className="nested_menu__back" onClick={close}>
                        Back
                    </button>
                    <Link href={"/categories/" + data.relative_url}>
                        <a className="nested_menu__title" onClick={handleClose}>
                            {language === "ua" ? data.name_ua : data.name}
                        </a>
                    </Link>
                </div>
                <ul>
                    {data.children.map(cat => (
                        <li className="nested_menu__item 1" key={cat.id}>
                            <Link href={`/categories/${prefix}/${cat.slug}`}>
                                <a onClick={handleClose}>{language === "ua" ? cat.name_ua : cat.name}</a>
                            </Link>
                            {cat.children.length > 0 && (
                                <button className="nested_menu__item--next" onClick={() => setClicked(cat)}>
                                    Next
                                </button>
                            )}
                        </li>
                    ))}
                </ul>
            </section>
            {clicked && clicked.children.length > 0 && (
                <RecursiveDropdown
                    data={clicked}
                    closeSubMenu={closeSubMenu}
                    prefix={`${prefix}/${clicked.slug}`}
                    close={() => setClicked(null)}
                    closeMenu={closeMenu}
                    openSubMenu={openSubMenu}
                    makeListeners={makeListeners}
                />
            )}
        </>
    );
};

export default withTranslation("header")(NestedMenu);
