import React from "react";
import { Link, i18n } from "../../../i18n";
import StackGrid from "react-stack-grid";

import "./NestedDropdown.scss";
import Arrow from "./Arrow";

import icon1 from "./tempIcons/budvnitstvo.svg";
import icon2 from "./tempIcons/dlya-avto.svg";
import icon3 from "./tempIcons/dlya-dach.svg";
import icon4 from "./tempIcons/dlya-domu.svg";
import icon5 from "./tempIcons/elektrika.svg";
import icon6 from "./tempIcons/nstrumenti.svg";
import icon7 from "./tempIcons/remont.svg";
import icon8 from "./tempIcons/santehnka.svg";
import { API_BASE_URL, CLEAN_API_URL } from "../../../context/appState";

const icons = {
    budvnitstvo: icon1,
    "dlya-avto": icon2,
    "dlya-dach": icon3,
    "dlya-domu": icon4,
    elektrika: icon5,
    nstrumenti: icon6,
    remont: icon7,
    santehnka: icon8
};

const ThirdChild = ({ name, prefix, cat }) => {
    return (
        <div>
            <Link href/* ="/categories/[...slug]" as */={`/categories/${prefix}/${cat.slug}`}>
                <a className="third-level">
                    <p>{name}</p>
                </a>
            </Link>
        </div>
    );
};

export default class NestedDropdown extends React.Component {
    state = {
        hovered: null
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.hovered?.id !== this.state.hovered?.id) {
            this.props.makeListeners();
        }
    }

    componentDidMount() {
        this.props.makeListeners();
    }

    render() {
        const { categories, close, isOpen, language } = this.props;
        const { hovered } = this.state;
        return (
            <div className="catalog-dropdown" style={{ display: isOpen ? "flex" : "none" }}>
                <div className="root">
                    {categories
                        .filter(obj => !["sale", "podarochnyy-sertifikat"].includes(obj.slug))
                        .map(cat => (
                            <Link href/* ="/categories/[...slug]" as */={`/categories/${cat.slug}`} key={cat.id}>
                                <a
                                    onClick={close}
                                    className={hovered === cat ? "catalog-category active" : "catalog-category"}
                                    onMouseOver={() => this.setState({ hovered: cat })}
                                >
                                    <img
                                        src={`${cat.icon || icons[cat.slug]}`.includes(CLEAN_API_URL) ? `${cat.icon || icons[cat.slug]}` : (CLEAN_API_URL + `${cat.icon || icons[cat.slug]}`)}
                                        alt="category icon"
                                        className="category-icon"
                                    />
                                    <p>{language === "ua" ? cat.name_ua : cat.name}</p>

                                    {cat.children.length > 0 && (
                                        <div className="arrow">
                                            <Arrow red={hovered === cat} />
                                        </div>
                                    )}
                                </a>
                            </Link>
                        ))}
                </div>
                {hovered && hovered.children.length > 0 && (
                    <div className="catalog-nested">
                        <div className="body">
                            <div className="catalog-nested-title">
                                {language === "ua" ? hovered.name_ua : hovered.name}
                            </div>
                            <StackGrid columnWidth={"33%"} enableSSR easing={false}>
                                {hovered.children.map(cat => (
                                    <div onClick={close} key={cat.id} className="second-important">
                                        <Link
                                            href/* ="/categories/[...slug]"
                                            as */={`/categories/${hovered.slug}/${cat.slug}`}
                                        >
                                            <a
                                                className={
                                                    hovered === cat
                                                        ? "catalog-nested-category active"
                                                        : "catalog-nested-category"
                                                }
                                            >
                                                <p className="second-important-text">
                                                    {language === "ua" ? cat.name_ua : cat.name}
                                                </p>
                                            </a>
                                        </Link>
                                        <div>
                                            {cat.children.map((child, idx) => (
                                                <ThirdChild
                                                    key={idx}
                                                    name={language === "ua" ? child.name_ua : child.name}
                                                    prefix={`${hovered.slug}/${cat.slug}`}
                                                    cat={child}
                                                    key={idx}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </StackGrid>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
